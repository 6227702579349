import React from "react"

class Character extends React.Component {
    render() {
        return(
            <div>
                <img className={this.props.Character[1]} src={this.props.Character[0]} alt="" onClick={this.props.action}/>
            </div>
        )
    }
  }
  
  export default Character;