import React from "react"
class Item extends React.Component {
    componentDidMount() {
        document.getElementById('dialog-rounded').showModal();
    }

    render() {
      return (
        <dialog className="nes-dialog is-rounded" id="dialog-rounded">
            <form method="dialog">
            <p className="title text-center">Item Erhalten:</p>
            <p className="text-center">{this.props.item_name}</p>
            <img alt="" className="object-center" style={{width: 200, marginLeft: 62}} src={`${this.props.item_pic}`}/>
            <menu className="dialog-menu">
                <button className="nes-btn is-primary" style={{width: 310}} onClick={this.props.action}>Okay</button>
            </menu>
            </form>
        </dialog>
      );
    }
  }
  
  export default Item;