import '../App.css';
import background from "../backgrounds/Pixel_City_GMK.png"
import logo from "../backgrounds/logo.png"
import { useState } from 'react';

function App() {
  const [error, setError] = useState(false)
  function showModal() {
    document.getElementById('dialog-default').showModal()
  }
  function checkInput() {
    document.getElementById('dialog-default').close()
    const input =  document.getElementById('name_field').value
    if(input !== "") {
      localStorage.setItem("player_name",input)
      window.location.href = "/game"
    } else {
      setError(true)
      showModal()
    }
  }
  return (
    <div className='Background' style={{backgroundImage: `url(${background})` }}>
      <img className="logo" alt="" src={logo}/>
      <button type="button" className="nes-btn is-success center" onClick={showModal}>Spiel Starten</button>
      <button type="button" className="nes-btn is-primary center" onClick={() => document.getElementById('dialog-credits').showModal()} style={{marginTop: 60, width: 400}}>Credits</button>
      <button type="button" className="nes-btn is- center" onClick={() => document.getElementById('dialog-quellen').showModal()} style={{marginTop: 120, width: 400}}>Quellen</button>
      <dialog className="nes-dialog is-rounded" id="dialog-default">
        <form method="dialog">
          <p className="title">Bevor wir loslegen:</p>
          <div className="nes-field">
            <label htmlFor="name_field">Wie soll dein Charakter heißen?</label>
            <input type="text" id="name_field" className="nes-input" />
            {error ?
            <span className="nes-text is-error">Du musst einen Namen angeben!</span>
            : ""}
          </div>
          <menu className="dialog-menu">
            <button className="nes-btn">Abbrechen</button>
            <button className="nes-btn is-primary" type="reset" onClick={() => checkInput()}>Starten</button>
          </menu>
        </form>
      </dialog>
      <dialog className="nes-dialog is-rounded" id="dialog-quellen">
        <form method="dialog">
          <p className="title">Verwendete Quellen:</p>
          <div className="lists">
            <ul className="nes-list is-circle">
              <li><a href="https://www.suedkurier.de/ueberregional/wirtschaft/Das-Verhaeltnis-von-Markt-und-Staat-Woran-krankt-die-Soziale-Marktwirtschaft;art416,9530390">https://www.suedkurier.de</a></li>
              <li><a href="https://www.bpb.de/kurz-knapp/lexika/lexikon-der-wirtschaft/20292/planwirtschaft/">https://www.bpb.de</a></li>
              <li><a href="https://thinkaboutgeny.com/planwirtschaft#tab-con-1">https://thinkaboutgeny.com</a></li>
              <li><a href="https://www.bmwi.de/Redaktion/DE/Reden/2022/20220128-rede-robert-habeck-in-der-plenarsitzung-des-deutschen-bundestages-am-28-januar-2022.html">https://www.bmwi.de</a></li>
              <li><a href="https://www.tagesschau.de/multimedia/video/video-795317.html">https://www.tagesschau.de</a></li>
              <li><a href="https://www.leipzigerinstitut.de/bwl/kosten/soziale-marktwirtschaft.html#:~:text=Markteingriffe%20des%20Staates%20Der%20Staat%20greift%20ein%3A%20wenn%2C,Gesamtwohls%20Ma%C3%9Fnahmen%20notwendig%20werden%2C%20Gesetz%20gegen%20Wettbewerbsbeschr%C3%A4nkung%20Kartellgesetz">https://www.leipzigerinstitut.de</a></li>
              <li><a href="https://www.umweltbundesamt.de/themen/wirtschaft-konsum/wirtschaft-umwelt#:~:text=Green%20Economy%20ist%20ein%20neues%20Leitbild%20f%C3%BCr%20wirtschaftliche,die%20im%20Einklang%20mit%20Natur%20und%20Umwelt%20steht">https://www.umweltbundesamt.de</a></li>
              <li><a href="https://www.europarl.europa.eu/news/de/headlines/economy/20190910STO60748/wie-konnen-wir-die-globalisierung-meistern-die-antworten-der-eu">https://www.europarl.europa.eu</a></li>
              <li><a href="https://www.tutoria.de/unterrichtsmaterialien/politik/probleme-soziale-marktwirtschaft#probleme">https://www.tutoria.de</a></li>
            </ul>
          </div>
          <menu className="dialog-menu">
            <button className="nes-btn" style={{width: 500, marginTop: 20}}>Schließen</button>
          </menu>
        </form>
      </dialog>
      <dialog className="nes-dialog is-rounded" id="dialog-credits">
        <form method="dialog" style={{width: 500}}>
          <p>Application developed by KoschSystems.de. All rights reserved</p>
          <br/>
          <p>Game Idea, Production, Scripts, Illustration by Anna, Leon, Elvan</p>
          <br/>
          <p>Benutze Technologien:</p>
          <div className="lists">
            <ul className="nes-list is-circle">
              <li><a href="https://reactjs.org/">Framework: React</a></li>
              <li><a href="https://nostalgic-css.github.io/NES.css/">Styling: NES.css</a></li>
              <li><a href="https://tailwindcss.com/">Styling: Tailwindcss</a></li>
              <li><a href="https://github.com/alampros/react-confetti">Effect: Confetti</a></li>
            </ul>
          </div>
          <menu className="dialog-menu">
            <button className="nes-btn" style={{width: 500, marginTop: 20}}>Schließen</button>
          </menu>
        </form>
      </dialog>
    </div>
    
    );
}

export default App;
