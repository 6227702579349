import React from "react"
import Dialog from "../Levels/Level_1.json"
import turnPhone from "../backgrounds/turn_mobile.png"
import Answer from './Game/Answer';
import Ask from "./Game/Ask";
import Character from "./Game/Charakter";
import Item from "./Game/Item";
import AskChange from "./Game/AskChange";
import AskLoop from "./Game/AskLoop";
import AskResult from "./Game/AskResult";

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      level: 0,
      stop: false,
      background: "",
      changeView: false,
      VisibleCharakters: [],
      scene: Dialog["data"]["start_point"]
    };
    
    this.horizontalView = this.horizontalView.bind(this);
    this.onMouseClick = this.onMouseClick.bind(this);
    this.removeStop = this.removeStop.bind(this)
    this.changeScene = this.changeScene.bind(this)
    this.removeStopGoOn = this.removeStopGoOn.bind(this)
  }
  horizontalView() {
    const { innerWidth: width, innerHeight: height } = window;
    this.setState({horizontal: width>height ? true : false})
  }
  removeStop() {
    this.setState({stop: false})
  }
  removeStopGoOn() {
    this.setState({askloop: null,stop: false})
    this.Loader(this.state.level)
  }
  changeScene(scenee,endScene = false) {
    if(endScene) {
      scenee = Dialog["data"]["end_scene"]
    }
    setTimeout(() => {
      this.setState({changeView: false,scene: scenee,askChange: null,stop: false})
      this.Loader(0)
    },500)
  }
  Loader(dialogLevel) {
    var dialogs = Dialog["data"]["dialogs"][this.state.scene][dialogLevel]
    this.setState({Talk: null,askResult: null,item: null,level: dialogLevel+1})
    switch (dialogs[0]) {
    case "setBackground": 
        this.import_picture("backgrounds",Dialog["data"]["backgounds"][dialogs[1]]).then(result => {
          this.setState({background: result})
        })
        this.Loader(dialogLevel+1)
    break;
    case "real_start": 
        localStorage.removeItem("result")
        localStorage.removeItem("left_paths")
        this.Loader(dialogLevel+1)
    break;
    case "setVisibleCharakters":
        var arr = []
        dialogs[1].forEach(char => {
            this.import_picture("Characters",Dialog["data"]["charakters"][char[0]]).then(result => 
              arr.push([result, char[1]])
            )
        })
        setTimeout(() => 
        this.setState({VisibleCharakters: arr})
        ,500)
        this.Loader(dialogLevel+1)
    break
    case "say": 
      this.setState({Talk: {name: dialogs[1], text: dialogs[2]}})
    break
    case "give_item": 
      this.import_picture("items",Dialog["data"]["items"][dialogs[1]]).then(result => 
        this.setState({item: {picture: result, name: dialogs[2]}})
      )
    break
    case "ask": 
      this.setState({stop: true, ask: {name: dialogs[1], text: dialogs[2]}})
    break
    case "ask_loop": 
      this.setState({stop:true, askloop: {name: dialogs[1], text: dialogs[2]}})
    break;
    case "ask_change": 
      this.setState({askChange: dialogs[1],stop: true,changeView: true})
    break;
    case "end": 
      this.changeScene(dialogs[1])
    break;
    case "next_selection": 
        if((JSON.parse(localStorage.getItem("left_paths"))).length === 0) {
          this.changeScene("",true)
        }
      this.setState({askChange: null,stop: true,changeView: true})
    break;
    case "ask_result": 
      this.setState({askResult: {name: dialogs[1],type: dialogs[2], text: dialogs[3]}, stop: true})
    break;
    case "real_end": 
      window.location.href = "/result"
    break;
    default: 
        console.log("Error: No command found in level")
    }
  }
  onMouseClick() {
    if(!this.state.stop) {
      this.Loader(this.state.level)
    }
  }
  async import_picture(path,picture) {
    var link = (await import("../"+path+"/"+picture)).default
    if(!link.includes("base64")) {
      link = (window.location.origin.toString() + link).toString()
    }
    return link
  }
  componentDidMount() {
    this.Loader(this.state.level)
    this.horizontalView()
    window.addEventListener('resize', this.horizontalView)
    this.setState({data: Dialog["data"][1]})
    if(localStorage.getItem("player_name") === null) {
      window.location.href = "/"
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.horizontalView)
  }
  Horizontal() {
    return (
      <div className="container mx-auto">
        <div className='flex items-center justify-center'>
          <img className="mx-auto" alt="" src={turnPhone} />
        </div>
        <p className="text-center">Bitte drehe dein Handy!</p>
    </div>
    )
  }
  render() {
    return (
    <div>
      {this.state.horizontal ?
      <div className='Background' style={{backgroundImage: `url(${this.state.background})` }}>
        {this.state.VisibleCharakters.map((Char,i) => 
          <Character key={i} Character={Char} action={this.onMouseClick}/>
          )}
        { this.state.item ?
          <Item item_pic={this.state.item.picture} item_name={this.state.item.name} action={this.onMouseClick}/>
        : ""}
        { this.state.ask ? 
          <Ask content={this.state.ask} next={this.removeStop}/>
        : ""}
        { this.state.Talk ? 
        <Answer text={this.state.Talk.text} name={this.state.Talk.name} />
        : ""}
        {this.state.changeView ?
          <AskChange paths={this.state.askChange} action={this.changeScene}/>
        : ""}
        {this.state.askloop ?
          <AskLoop content={this.state.askloop} action={this.removeStopGoOn}/>
        : ""}
        {this.state.askResult ?
          <AskResult content={this.state.askResult} next={this.removeStop}/>
        : ""}
      </div>
      : this.Horizontal()}
    </div>
    );
  }
}

export default Game;
