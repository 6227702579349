import React from "react"
import Answer from "./Answer";
class AskLoop extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: false,
            questions: this.props.content.text
          };
          this.checkBoxClicked = this.checkBoxClicked.bind(this)
          this.next = this.next.bind(this)
        }
    checkBoxClicked(i) {
        this.setState({text: this.state.questions[i][1],name: this.props.content.name,answer: true})
        let newState = [...this.state.questions]
        newState.splice(i,1)
        this.setState({questions: newState})
        setTimeout(() => {window.addEventListener('click', this.next)},1000)
    }
    next() {
        window.removeEventListener('click',this.next)
        if(this.state.questions.length === 0) {
            this.props.action()
        } else {
            this.setState({answer: false})
        }
    }

    render() {
      return (
          <div>
        {this.state.answer ?
            <Answer text={this.state.text} name={this.state.name} />
        : 
        <div className="nes-container is-rounded with-title is-centered bg-white w-5/12 answer">
            <div className="textscroll">
            {this.state.questions.map((element,i) => {
                return (<div key={i}><label>
                    <input type="radio" className="nes-radio" defaultChecked onClick={() => {this.checkBoxClicked(i)}}/>
                    <span >{element[0]}</span>
                </label><br/></div>)
            })}
            </div>
        </div>
        }
        </div>
      );
    }
  }
  
  export default AskLoop;