import React from "react"
import LevelFile from "../Levels/Level_1.json"
import Background from "../backgrounds/bg_End_Elmshorn.png"
import Confetti from 'react-confetti'
class Result extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: 0,
            result: {
                answer: null,
                result: null
            },
            end: false

        }
        this.readResults = this.readResults.bind(this)
    }
    componentDidMount() {
        const local_results = localStorage.getItem("result")
        if(local_results === null) {
            window.location.href = "/"
        }
        this.readResults()
    }
    readResults() {
        const result_data = JSON.parse(localStorage.getItem("result"))
        if(result_data.length >= (this.state.answer)+1) {
            const answer_class = result_data[this.state.answer][0]
            const answer_id = result_data[this.state.answer][1]
            
            const end_scene = LevelFile["data"]["dialogs"][LevelFile["data"]["end_scene"]]
            var answer_obj = end_scene.find(element => {
                return element[2] === answer_class
            })
            var answer = answer_obj[3].find(element => {
                return element[0] === answer_id
            })
            this.setState({result: {answer: answer[1] , result: LevelFile["data"]["results"][answer_class][answer_id]}})
        } else {
            this.setState({result: null,end: true})
            localStorage.clear();
        }


    }

    render() {
      return (
        <div>
            <div className='Background' style={{backgroundImage: `url(${Background})` }}>
            {this.state.result ? 
                <div className="center"> 
                    <div className="nes-container with-title is-centered bg-white resultbox">
                        <p className="title">Ausgewählte Frage {(this.state.answer)+1} :</p>
                        <p className="resulttextscroll">{this.state.result.answer}</p>
                    </div>
                    <div className="nes-container with-title is-centered bg-white resultbox" style={{marginTop: 20 }}>
                        <p className="title">Auswertung der Frage</p>
                        <p className="resulttextscroll">{this.state.result.result}</p>
                    </div>
                    <button type="button" className="nes-btn is-success resultbutton" onClick={() =>{this.setState({answer: (this.state.answer)+1},this.readResults)}}>Weiter</button>
                </div>
                : ""}
                {this.state.end ?
                    <div>
                        <Confetti/> 
                        <div className="nes-container with-title is-centered bg-white center resultboxend">
                            <p className="title">Du hast es geschaft 🥳🥳🥳:</p>
                            <p>Wir bedanken uns für das Spielen unseres Spiels. Gerne kannst du jeder zeit von Vorne anfangen und einen anderen Weg ausprobieren.</p>
                            <button type="button" className="nes-btn is-success" onClick={() => window.location.href = "/"} style={{width: 450, marginTop: 20 }}>Zum Start</button>
                        </div>
                    </div>
                : ""}
            </div>
        </div>
      );
    }
  }
  
  export default Result;