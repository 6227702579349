import React from "react"
import Answer from "./Answer";
class Ask extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: false,
          };
          this.checkBoxClicked = this.checkBoxClicked.bind(this)
        }
    checkBoxClicked(i) {
        this.setState({text: this.props.content.text[i][1],name: this.props.content.name,answer: true})
        this.props.next();
    }

    render() {
      return (
          <div>
        {this.state.answer ?
            <Answer text={this.state.text} name={this.state.name} />
        : 
        <div className="nes-container is-rounded with-title is-centered bg-white w-5/12 answer">
            {this.props.content.text.map((element,i) => {
                return (<label key={i}>
                    <input type="radio" className="nes-radio" name={i} defaultChecked onClick={() => {this.checkBoxClicked(i)}}/>
                    <span >{element[0]}</span>
                </label>)
            })}
            <br />
        </div>
        }
        </div>
      );
    }
  }
  
  export default Ask;