import React, { useEffect, useState } from "react"

function AskChange(props) {
    const [path, setPath] = useState([])
    const [block, setBlock] = useState(false)

    function checkBoxClicked(i) {
        let newState = [...path]
        newState.splice(i,1)
        localStorage.setItem("left_paths",JSON.stringify(newState))
        props.action(path[i][1])
    }
    useEffect(() => {
        if(!block) {
            setChanges(props)
            setBlock(true)
        }
    });
    function setChanges(props) {
        if(props.paths != null) {
            setPath(props.paths)
            localStorage.setItem("left_paths",JSON.stringify(props.paths))
        } else {
            setPath(JSON.parse(localStorage.getItem("left_paths")))
        }
    }
    return (
        <div>
            <div className="nes-container is-rounded with-title is-centered bg-white w-5/12 answer">
                <div className="textscroll">
                    {path.map((element,i) => {
                        return (<div key={i}><label>
                            <input type="radio" className="nes-radio" name={i} defaultChecked onClick={() => {checkBoxClicked(i)}}/>
                            <span >{element[0]}</span>
                        </label><br /></div>)
                    })}
                </div>
            </div>
        </div>  
    )
}
export default AskChange;