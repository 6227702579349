import React from "react"

function Answer(props) {
    function return_replace() {
      var text = props.text
      var player_name = localStorage.getItem("player_name") === null ? "Player": localStorage.getItem("player_name")
      var name = props.name ==="Player" ? localStorage.getItem("player_name"): props.name;
      text = text.replace("%name%",player_name)

      return (
        <div className="nes-container is-rounded with-title is-centered bg-white answer">
            <p className="title">{name}</p>
              <p className="textscroll">{text}</p>
        </div>
      );
    }
    return return_replace()
    
}
  
  export default Answer;