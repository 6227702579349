import React from "react"
import Answer from "./Answer";
class AskResult extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: false,
          };
          this.checkBoxClicked = this.checkBoxClicked.bind(this)
        }
    checkBoxClicked(i) {
        this.setState({text: this.props.content.text[i][2],name: this.props.content.name,answer: true})
        var loc_result = JSON.parse(localStorage.getItem("result"))
        if(loc_result === null) {
            localStorage.setItem("result",JSON.stringify([[this.props.content.type,this.props.content.text[i][0]]]))
        } else {
            loc_result.push([this.props.content.type,this.props.content.text[i][0]])
            localStorage.setItem("result",JSON.stringify(loc_result))
        }
        this.props.next();
    }

    render() {
      return (
          <div>
        {this.state.answer ?
            <Answer text={this.state.text} name={this.state.name} />
        : 
        <div className="nes-container is-rounded with-title is-centered bg-white w-5/12 answer">
            <div className="textscroll">
            {this.props.content.text.map((element,i) => {
                return (<div key={i}><label>
                    <input type="radio" className="nes-radio" name={i} defaultChecked onClick={() => {this.checkBoxClicked(i)}}/>
                    <span >{element[1]}</span>
                </label><br/></div>)
            })}
            <br />
            </div>
        </div>
        }
        </div>
      );
    }
  }
  
  export default AskResult;